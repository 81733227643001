<template>
  <v-container
    class="pt-0 pb-0"
    :class="{ 'pr-0 pl-0': !$vuetify.breakpoint.mdAndUp }"
  >
    <v-card width="100%" class="card_background">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-container class="pb-0">
            <div class="d-flex justify-space-between">
              <p class="text-h6">Descuentos</p>
              <v-btn class="success">Crear</v-btn>
            </div>
          </v-container>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-tabs :value="tabSection" grow>
            <v-tab class="caption">Códigos</v-tab>
            <v-tab class="caption">Descuentos automáticos</v-tab>

            <v-tab-item :key="0" class="card_background">
              <v-slide-x-transition leave-absolute mode="in-out">
              </v-slide-x-transition>
              <v-container v-if="notFound">
                <div class="d-flex justify-center my-4">
                  <div>
                    <v-img
                      width="100"
                      src="@/assets/icons/discount_color.svg"
                    ></v-img>
                  </div>
                </div>
                <p class="text-subtitle-1 text-center">Códigos de descuento</p>
                <div class="mx-4 mb-4 caption text-center">
                  Crea códigos de descuento que se apliquen en la pantalla de
                  pago, con ellos tus clientes y tu pueden cajear los códigos
                  para obtener el descuento correspondiente.
                </div>
                <div class="text-center">
                  <v-btn color="success" :to="{ name: 'discountRegister' }"
                    >Crear código de descuento</v-btn
                  >
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1" class="card_background">
              <v-container v-if="notFound">
                <div class="d-flex justify-center my-4">
                  <div>
                    <v-img
                      width="100"
                      src="@/assets/icons/cart_discount_color.svg"
                    ></v-img>
                  </div>
                </div>
                <p class="text-subtitle-1 text-center">Descuento automáticos</p>
                <div class="mx-4 mb-4 caption text-center">
                  Crea descuentos que se apliquen automáticamente al carrito de
                  un cliente.
                </div>
                <div class="text-center">
                  <v-btn color="success" :to="{ name: 'discountAutoRegister' }"
                    >Crear descuento automático</v-btn
                  >
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tabSection: 0,
      // ::
      productData: null,
      productDatabaseRef: null,
      loader: true,
      notFound: true
    };
  }
};
</script>
<style>
.v-rating .v-icon {
  padding: 0 !important;
}
</style>
